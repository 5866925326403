import React from 'react';

const Card = ({ className, children }) => (
  <div
    className={`p-12 rounded-lg border border-solid border-gray-200 bg-primary-lighter ${className}`}
    style={{
      boxShadow: '0 10px 28px rgba(0,0,0,.08)',
      color:'white'
    }}
  >
    {children}
  </div>
);

export default Card;