import React from 'react';

const HeroCard = ({ className, children }) => (
  <div
    className={`p-12 ${className}`}
    style={{
      boxShadow: '0 10px 28px rgba(0,0,0,.08)',
    }}
  >
    {children}
  </div>
);

export default HeroCard;
