import React from 'react';
import {Link} from 'gatsby'
import { Helmet } from 'react-helmet';
import Button from '../components/Button';
import HeroCard from '../components/HeroCard'
import ShapeCard from '../components/ShapeCard'
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';

import Logo from '../svg/on-the-block-real-estate-logo.svg'

const Index = () => (
  <Layout>
    <Helmet   
      htmlAttributes={{
      lang: 'en',
    }}>
    <title lang="en">On The Block Real Estate International | Brokered By Exp Realty</title>
    <meta name="description" content="On The Block Has Been Serving California For More Than 20+ Years, 
    Helping Buyers With Their First Homes & Income Properties. Connect With Us Today & Learn More 
    About Our Full Service Brokerage Powered By Exp Realty - Los Angeles, CA. " lang="en"/>
  </Helmet>

    <section className="pt-20 md:pt-40 HeroBackground">
      <div className="container mx-auto px-4 lg:flex">
        <div className="lg:text-left">
          <h1 className="text-6xl lg:text-6xl xl:text-7xl font-bold leading-none text-white TextShadow">
            Experts Committed <br/>
            To Your Experience
          </h1>
        </div>
      </div>

      <div className="container mx-auto text-center">
      <div className="flex flex-col sm:flex-row sm:-mx-5 mt-32">
        <div className="flex-1">
          <HeroCard className="bg-primary LeftHeroCard">
            <p className="font-semibold text-xl text-white">For Home Buyers & Owners</p>
            <p className="mt-4 text-white">
              We are here to help you along every step of the process, whether you are buying 
              or selling.
            </p>
            <p className="mt-8">
            <a href="https://donaldsutton.exprealty.com/" target="_blank" rel="noopener noreferrer nofollow">
             <Button size="l">Learn More</Button>
            </a>
          </p>
          </HeroCard>
        </div>
        <div className="flex-1">
          <HeroCard className="bg-primary-darker RightHeroCard">
            <p className="font-semibold text-xl text-white">For Real Estate Agents</p>
            <p className="mt-4 text-white">
               Transform the real estate experience with On The Block Real Estate and keep 
               more of your comission.
            </p>
            <p className="mt-8">
            <Link to="/join-us" alt="Join On The Block Real Estate">
              <Button size="l">Learn More</Button>
            </Link>
            </p>
          </HeroCard>
        </div>
      </div>
    </div>
    </section>

    <SplitSection
      reverseOrder
      primarySlot={
        <div className="pt-20 lg:pl-20 xl:pl-20 sm:pb-10">
          <h2 className="text-3xl font-semibold leading-tight">
            Dedicated Team <br/>On Your Block
          </h2>
          <p className="mt-8 text-xl font-light leading-relaxed">
          On The Block Real Estate International concentrates on Educating Buyers and Sellers of 
          property to make the Right Financial Choices for themselves and their families. We are 
          not Salespeople. We are Educators. We inform, educate and teach all property Sellers 
          and Buyers about how to properly market their properties.
          </p>
        </div>
      }
      secondarySlot={<div className="PlaceHolder"></div>}
    />

    <section id="features" className="pt-5 MissionComponent">
      <div className="container mx-auto">
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
        <div className="flex-1 px-10 pb-20">
          <h2 className="text-3xl lg:text-5xl font-semibold text-white">Our Mission</h2>
          <p className="text-white ExperienceText">
          Our ongoing mission is to provide the highest level of service to our clients to 
          help them when buying or selling. We aim to assist you in making the best real 
          estate decision possible based on your needs.
          </p>

        </div>
        <div className="flex-1 px-10">
          <h2 className="text-3xl lg:text-5xl font-semibold text-white">Experienced</h2>
          <p className="text-white ExperienceText">
          We could be your best friend, but if we were dog groomers, you probably wouldn’t 
          want us doing your real estate work. Our team has spent over 10 years studying, 
          learning, and acclimating ourselves to your needs. So let us worry about the real estate side.
          </p>
        </div>
        </div>

        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3 bg-primary-lighter ShapeCard">
            <ShapeCard className="mb-3">
              <h2 className="font-semibold text-xl text-white mb-5">The Best Way To Know is To Explore Us</h2>
             <Link to="/about-us" alt="Learn About On The Block Real Estate">
              <Button size="m" className="bg-primary">Learn More</Button>
            </Link>
            </ShapeCard>
          </div>
          <div className="flex-1 px-5">
            <ShapeCard className="mb-3">
              <img src={Logo} alt="On The Block Real Estate International Logo"/>
            </ShapeCard>
          </div>
        </div>
      </div>
    </section>

    <section id="stats" className="py-20 lg:pt-32 ImageBreak">
      <div className="container mx-auto text-center">
        <LabelText className="text-white text-2xl">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="100%" secondaryText="UNBIASED CONSULTING" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="100%" secondaryText="LOCAL EXPERTISE" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="100%" secondaryText="REGULATORY GUIDANCE" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What Our Clients Have To Say</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Ready To Connect With Us?</h3>
      <p className="mt-8 mb-5 mx-5 text-xl font-light">
        We are available 7-days week, give us a call today, or feel free to send us a message
        through our chat.
      </p>
      <a href="tel:562-972-1390">
        <Button size="l">
          Call Now
        </Button>
      </a>
    </section>
  </Layout>
);

export default Index;
