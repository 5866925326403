import React from 'react';

const ShapeCard = ({ className, children }) => (
  <div
    className={`p-12 ${className}`}
    style={{
      color: 'white'
    }}
  >
    {children}
  </div>
);

export default ShapeCard;
