export default [
  {
    title: 'Google - ★★★★★',
    content:
      'Ernesto from On the Block Real Estate was excellent and efficient on getting my first house. He took the time to ask what kind of house we were looking for and the budget that we wanted to stay under…at the end we ended up getting our number one picked thanks to him. We recommend On The Block Real Estate because they really help you find the perfect home in an efficient and fast way.',
    customerName: 'Ruben and Yexenia',
    customerTitle: 'Highland, CA',
    customerImage: 'https://res.cloudinary.com/on-the-block-real-estate/image/upload/v1627349487/Clients/sabrina_mt903a.png'
  },
  {
    title: 'Yelp - ★★★★★',
    content:
      'We had a great experience using Dons services to purchase our new home. Don is knowledgeable, helpful and very professional. He listens to exactly what you are looking for, and works with you to show you homes in the areas that you want and with the features that you want. He is always on top of his stuff, and you will always be dealing directly with him and only him.',
    customerName: 'Lezlye Z.',
    customerTitle: 'Gardena, CA',
    customerImage: 'https://res.cloudinary.com/on-the-block-real-estate/image/upload/v1627349481/Clients/Lezlye-z_jljomz.png'
  },
  {
    title: 'Facebook - ★★★★★',
    content:
      'We approached Don and from our first communication, he was prompt and was immediately interested in our needs. We are first time buyers and obviously experience the "jitters". You want to make sure your first home is exactly what you want and where you want it. Dons experience was just what we needed. He informed us about our options and locations.',
    customerName: 'Ana H.',
    customerTitle: 'Bellflower, CA',
    customerImage: 'https://res.cloudinary.com/on-the-block-real-estate/image/upload/v1627349478/Clients/anna-hebet_phodfv.png'
  }
];
